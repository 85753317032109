/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

:root {
    --mainColorLight: #F5F5F5;
    --mainColorDark: #090909;
    --textColor: #0e0e0e;
    --accentColor1: #54c6eb;
    --accentColor2: #2ecc71;
    --accentColor3: #e87461;
    --overlayColor: rgba(14, 14, 14, 0.5);
    --fontFamily: 'Poppins', sans-serif;
}

html,
body {
    background-color: var(--mainColorLight);
    font-family: var(--fontFamily);
}